<template>
	<div class="itemContainer">
		<div class="text" :class="snippetClass" v-show="link != '' || edit">
			<h3 v-if="link != '' || edit">
				{{$t(header)}} <span class="required" v-show="required && edit">*</span>
				<ExtraInfo :id="header" v-if="edit"></ExtraInfo>
			</h3>
			<div :id="header" v-if="edit">
				<input v-model="link" @change="change" @input="onDirty" @onblur="onBlur" />
			</div>
			<ReadMore :maxHeight="0" v-else-if="link != ''" @toggle="readMoreIsToggled">
				<a :class="['details', !readMoreIsClosed ? 'hva-caret-right' : '']" :href="text.split(';#;')[1]" target="_blank">{{$t(header)}} {{text.split(";#;")[0]}}</a>
			</ReadMore>
		</div>
	</div>
</template>

<script>
        /*eslint-disable */
import Item from "../../mixins/Item.js";
import ReadMore from "common/components/General/ReadMoreHeight";
import ExtraInfo from "./ExtraInfo";

export default {
	name: "link-item",
	components: {
		ReadMore, ExtraInfo
	},
	mixins: [Item],
	props: {
		required: Boolean
	},
	data: function() {
		return {
			text: "",
			link: "",
			readMoreIsClosed: true
		};
	},
	methods: {
		change(){
			var model = this;
			this.onDirty();
			this.text = this.text.split(';#;')[0] + ";#;" + this.link;
			this.$emit("change", model.header, model.link);
		},
		onBlur() {
            this.$emit("blur");
		},
		onSave() {
			this.revert = this.text;
		},
		toOriginal() {
            this.text = this.revert;
			this.link = this.revert.split(';#;')[1]
		},
		readMoreIsToggled(){
			this.readMoreIsClosed = !this.readMoreIsClosed;
		}
	},
	computed: {
		snippetClass() {
			return this.edit ? `${this.$i18n.locale}` : "";
		},
	},
        created() {

            this.link = this.text.split(';#;')[1];
	}
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";

</style>
