<template>
	<div v-if="data">
		<div class="data-title">
			<h4 v-if="h4">{{label}}</h4>
			<h3 v-else>{{label}}</h3>
		</div>
		<div class="data-content data-clean text-content">
			<div v-html="data"></div>
		</div>
	</div>
</template>
<script>
export default {
	name: "datadisplay",
	props: {
		data: String,
		label: String,
		h4: Boolean
	}
};
</script>