<template>
  <div :class="isBanner == true ? 'text banner' : 'text'">
    <h3>
      {{ $t(header) + (edit ? " (Preview)" : "") }}
    </h3>
    <ReadMore :maxHeight="readMoreMaxHeight" v-if="isBanner != true">
      <div class="view" v-html="text" />
    </ReadMore>
    <div v-else class="view banner-text" v-html="dateText" style="margin-bottom: 0px!important" />
    <div v-if="isBanner == true" class="arrows">
      <div class="arrow-left-20" />
      <div class="arrow-left-40" />
      <div class="arrow-left-80" />
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import settings from "../../assets/settings.json";
import Item from "../../mixins/Item.js";
import FroalaEditor from "froala-editor";
import ReadMore from "common/components/General/ReadMoreHeight";
import ExtraInfo from "./ExtraInfo";
var language = "nl_NL";
if (location.host.indexOf("coursecatalogue") != -1) {
  language = "en_GB";
}

window.WEBSPELLCHECKER_CONFIG = {
  autoSearch: true,
  autoDestroy: true,
  serviceId: settings.webspellcheckerkey,
  lang: language,
};

export default {
  name: "local-text-item",
  components: {
    ReadMore,
    ExtraInfo,
  },
  mixins: [Item],
  props: {
    maxChar: Number,
    readMoreMaxHeight: Number,
    isBanner: Boolean,
    date: String,
  },
  data: function() {
    return {
      config: null,
      text: "",
      editor: null,
    };
  },
  computed: {
    dateText() {
      var nl = this.text.split("*DATUM*");
      var en = this.text.split("*DATE*");
      var result = "";
      var d = new Date(this.date);
      if (nl.length > 1) {
        result += nl[0];
        result += d.toLocaleString("nl-nl", { day: "numeric", month: "long" });
        result += nl[1];
      } else if (en.length > 1) {
        result += en[0];
        result += d.toLocaleString("nl-nl", { day: "numeric", month: "long" });
        result += en[1];
      } else {
        result += this.text;
      }
      return result;
    },
    snippetClass() {
      return this.edit ? `${this.$i18n.locale}` : "";
    },
    headerId() {
      var headerArray = this.header.split(".");
      return headerArray[headerArray.length - 1];
    },
    currentCharLeftCount: function() {
      if (this.text === null) {
        return this.maxChar;
      }
      var x = this.$(document.createElement("DIV"));
      var charCount = x
        .html(this.text)
        .text()
        .replace(/\s/g, ""); //strip html and remove whitespaces
      return this.maxChar - charCount.length;
    },
  },
  watch: {
    text: function() {
      this.checkRequiredness();
    },
    edit: function(value) {
      var model = this;
      if (value) {
        this.$nextTick(() => {
          model.loadEditor();
        });
      }
    },
  },
  created() {
    var model = this;

    this.$on("locale", (lang) => {
      model.config.language = lang;
      model.loadEditor();
    });

    var toolbar = {
      moreText: {
        buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "clearFormatting"],
      },
      moreParagraph: {
        buttons: ["formatOLSimple", "formatUL", "paragraphFormat", "alignLeft", "alignCenter", "alignRight", "alignJustify", "outdent", "indent"],
      },
      moreRich: {
        buttons: ["insertLink", "insertImage", "insertVideo", "insertTable", "specialCharacters", "insertHR"],
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: ["undo", "redo", "fullscreen", "help"],
        buttonsVisible: 4,
      },
    };
    if (this.header == "video") {
      toolbar = ["insertVideo"];
    }

    this.config = {
      events: {
        blur: () => {
          model.$emit("blur");
        },
        contentChanged: () => {
          if (model.editor.core.isEmpty()) {
            model.text = "";
          } else {
            var html = model.editor.html.get();
            if (html.includes("<iframe") || html.includes("<img") || html.includes("<video")) {
              model.text = html;
            } else {
              var temp = document.createElement("div");
              temp.innerHTML = html;
              if (temp.innerText.trim() != "") {
                model.text = html;
              } else {
                model.text = "";
              }
            }
          }
          model.$emit("change", model.header, model.text);
          model.onDirty();
        },
        "html.get": (html) => {
          var textualHtml = html;
          var currentHtml = model.$.parseHTML(html);

          while (currentHtml.length == 1 && currentHtml[0].nodeName == "DIV" && currentHtml[0].attributes.length > 0 && currentHtml[0].attributes[0].name.startsWith("data-v-")) {
            textualHtml = currentHtml[0].innerHTML;
            currentHtml = model.$.parseHTML(textualHtml);
          }
          return textualHtml;
        },
        initialized: () => {
          WEBSPELLCHECKER.init({
            container: document.getElementById(this.headerId),
            lang: model.spellCheckLang ? model.spellCheckLang : language,
          });
        },
      },
      spellcheck: false,
      initOnClick: true,
      placeholderText: "",
      toolbarButtons: toolbar,
      imageUpload: true,
      imagePaste: true,
      videoUpload: false,
      imageEditButtons: ["imageReplace", "imageAlign", "imageRemove", "imageDisplay", "imageAlt", "imageSize"],
      tableEditButtons: ["tableHeader", "tableRows", "tableColumns", "-", "tableCells", "tableCellHorizontalAlign", "tableRemove"],
      language: this.$i18n.locale,
      imageDefaultAlign: "left",
      wordPasteModal: false,
      wordDeniedAttrs: ["style"],
      attribution: false,
      key: settings.froalaApi,
      imageUploadURL: "/api/blob/UploadImage",
      imageMaxSize: 5 * 1024 * 1024,
      imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
    };
  },
  mounted() {
    var model = this;
    this.checkRequiredness();
    this.$nextTick(() => {
      model.loadEditor();
    });
  },
  methods: {
    onSave() {
      this.revert = this.text;
    },
    toOriginal() {
      this.text = this.revert;
      if (this.editor) {
        this.editor.html.set(this.text);
      }
    },
    loadEditor() {
      if (this.edit) {
        if (this.editor && this.editor.destroy) {
          this.editor.destroy();
        }
        this.editor = new FroalaEditor(`#${this.headerId}`, this.config);
      }
    },
    checkRequiredness() {
      if (this.required) {
        if (this.currentCharLeftCount === this.maxChar) {
          this.$emit("requiredFieldNotFilled", this.header);
        } else {
          this.$emit("requiredFieldFilled", this.header);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";

.banner {
  z-index: 0;
  position: relative;
  padding: 20px;
  padding-right: 0px;
  margin-left: -20px;
  min-height: 175px;
  background: #351fb7;
}

.banner-text,
.banner-text * {
  padding-right: 30px;
  color: white;
}

.banner-header {
  color: white !important;
  font-weight: bold !important;
}
.arrows {
  position: absolute;
  top: 0px;
  right: 0px;
}

.arrow-left-20 {
    z-index: -1;
    position: absolute;
    top: 5px;
    right: 70px;
    height: 0;
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-right: 40px solid #f35279;
}

.arrow-left-40 {
    z-index: -1;
    position: absolute;
    width: 0;
    right: 160px;
    height: 0;
    top: 130px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 32px solid #1e1649;
}

.arrow-left-80 {
    z-index: -1;
    position: absolute;
    top: -240px;
    right: 350px;
    width: 0;
    height: 0;
    border-top: 250px solid transparent;
    border-bottom: 250px solid transparent;
    border-left: 400px solid #1e1649;
}
</style>
