<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper clone" @click="$emit('close')">
        <div class="modal-container" @click.stop>
          <div class="modal-header">
            <h2>{{ header }}</h2>
          </div>
          <div class="modal-body">
            <p>{{text}}</p>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button button button-primary" @click="$emit('ok')" type="button">
                {{ $t("general.ok") }}
              </button>
              <button class="modal-default-button button button-secondary" @click="$emit('close')" type="button">
                {{ $t("general.cancel") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "confirmationModal",
  props: {
    header: String,
    text: String,
  },
};
</script>
<style lang="less" scoped>
#editorLinkInput {
  width: 100%;
}
#app {
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 80%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    max-height: 80vh;
    margin-top: 10%;
    margin-bottom: 10%;
    overflow-y: auto;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  a.readmore,
  a.readmore:visited {
    color: blue;
  }

  table {
    width: 100%;
  }

  .clone .modal-header {
    padding-left: 0;
    padding-right: 0;
  }

  .clone .modal-header h2 {
    color: #25167a;
    font-size: 1.25em;
  }

  .clone .modal-body,
  .clone .modal-footer {
    padding-left: 0;
    padding-right: 0;
  }

  .modal-container {
    max-width: 1024px;
  }
  .button{
    min-width: 100px;
  }
  .button-primary{
    border: solid 2px;
    border-color: #007C68;
    border-radius: 5px;
  }
}
</style>
