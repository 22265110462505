<template>
    <div v-if="data">
        <div class="data-title">
            <h3>{{label}}</h3>
        </div>
        <ExtraInfo :inBlock="true" :id="infoKey"></ExtraInfo>
        <div class="data-content data-clean text-content">
            <div v-html="data"></div>
        </div>
    </div>
</template>
<script>
import ExtraInfo from "./ExtraInfo";
export default {
    name: "DataDisplayInBlock",
    components: {
        ExtraInfo
	},
	props: {
		data: String,
        label: String,
        infoKey: String
	}
};
</script>
<style lang="less" scoped>
    .data-title {
        float:left;
    }
</style>