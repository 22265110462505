<template>
    <div style="margin-bottom: 25px !important">
        <template v-if="!edit">
            <div v-if="valueObject.Info.SisData.CrohoName || valueObject.Info.SisData.CrohoCode || valueObject.Level || valueObject.ModeOfStudy || valueObject.Info.Duration || valueObject.Info.Credits || valueObject.Info.LanguageOfInstruction || valueObject.Info.StartMonth">
                <h3>{{$t('programOverview.programmeDetails.label')}}</h3>
                <div class="data-clean">
                    <ReadMore grayblock :maxHeight="0">
                        <datadisplay :data="valueObject.Info.SisData.CrohoName" :label="$t('programOverview.programmeDetails.crohoname')"></datadisplay>
                        <datadisplay :data="valueObject.Info.SisData.CrohoCode" :label="$t('programOverview.programmeDetails.crohocode')"></datadisplay>
                        <datadisplay :data="valueObject.Level" :label="$t('programOverview.programmeDetails.level')"></datadisplay>
                        <datadisplay :data="valueObject.ModeOfStudy" :label="$t('programOverview.programmeDetails.modeOfStudy')"></datadisplay>
                        <datadisplay :data="valueObject.Info.Traject" :label="$t('programOverview.programmeDetails.track')"></datadisplay>
                        <datadisplay :data="valueObject.Info.Duration" :label="$t('programOverview.programmeDetails.duration')"></datadisplay>
                        <datadisplay :data="valueObject.Info.Credits" :label="$t('programOverview.programmeDetails.credits')"></datadisplay>
                        <datadisplay :data="valueObject.Info.LanguageOfInstruction" :label="$t('commonLabels.language')"></datadisplay>
                        <datadisplay :data="valueObject.Info.StartMonth" :label="$t('programOverview.programmeDetails.start')"></datadisplay>
                    </ReadMore>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="readonly">
                <div class="left">
                    <h3>{{$t('programOverview.programmeDetails.label')}}</h3>
                </div>
                <div class="programDetailInfo">
                    <ExtraInfo id="ProgrammeDetails"></ExtraInfo>
                </div>
                <div class="clear"></div>
                <div class="is-gray-block data-clean">
                    <DataDisplayInBlock v-if="valueObject.Info.SisData.CrohoName" infoKey="Info.SisData.CrohoName" :data="valueObject.Info.SisData.CrohoName" :label="$t('programOverview.programmeDetails.crohoname')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Info.SisData.CrohoCode" infoKey="Info.SisData.CrohoCode" :data="valueObject.Info.SisData.CrohoCode" :label="$t('programOverview.programmeDetails.crohocode')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Level" infoKey="Level" :data="valueObject.Level" :label="$t('programOverview.programmeDetails.level')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.ModeOfStudy" infoKey="ModeOfStudy" :data="valueObject.ModeOfStudy" :label="$t('programOverview.programmeDetails.modeOfStudy')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Info.Traject" infoKey="Info.Traject" :data="valueObject.Info.Traject" :label="$t('programOverview.programmeDetails.track')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Info.Duration" infoKey="Info.Duration" :data="valueObject.Info.Duration" :label="$t('programOverview.programmeDetails.duration')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Info.Credits" infoKey="Info.Credits" :data="valueObject.Info.Credits" :label="$t('programOverview.programmeDetails.credits')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Info.LanguageOfInstruction" infoKey="Info.LanguageOfInstruction" :data="valueObject.Info.LanguageOfInstruction" :label="$t('commonLabels.language')"></DataDisplayInBlock>
                    <DataDisplayInBlock v-if="valueObject.Info.StartMonth" infoKey="Info.StartMonth" :data="valueObject.Info.StartMonth" :label="$t('programOverview.programmeDetails.start')"></DataDisplayInBlock>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import Item from "../../mixins/Item.js";
import ReadMore from "common/components/General/ReadMoreHeight";
import Datadisplay from "common/components/General/Datadisplay.vue";
import DataDisplayInBlock from "./DataDisplayInBlock.vue";
import ExtraInfo from "./ExtraInfo";

export default {
	name: "readOnlyBlock-item",
	mixins: [Item],
	components: {
        DataDisplayInBlock,
        Datadisplay,
        ReadMore,
        ExtraInfo
	},
	props: {
		required: Boolean
	},
	data: function() {
		return {
			valueObject: "",
		};
	},
	computed: {
	},
	created() {
		this.valueObject = this.value;
	},
	methods: {
		onSave() {},
		toOriginal() {}
	}
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";
.readonly {
	.snippet("");
	&::before {
		content: "Read-only";
	}
}
    .left {
        float:left;
    }
    .container {
        padding: 5px;
    }
    .clear {
        clear:both;
    }
    .programDetailInfo {
        margin-top:9px;
    }
</style>
