<template>
  <div class="bootstrap-hva">
    <div class="container gutter-hva fix-mobile-width">
      <editorBar
        v-if="programDetails && programDetails.Info && userCanEditProgram"
        v-model="programDetails.Info.EditorialState"
        :isEditing="isEditing"
        :isSaving="isSaving"
        :isDirty="isDirty"
        :saveMessage="saveMessage"
        :showCourseCatalogWarning="isExchange && programDetails.ShowInCourseCatalogOrShowInCourseCatalogEdit"
        :croppers="activeCroppers.length"
        @switchView="onSwitchView"
        @save="saveProgram"
      />
      <overview-header :program="programDetails" :isEditing="isEditing" />
      <div class="row acyearRow" v-if="!loading">
        <div class="col-md-3"></div>
        <div class="col-md-9">
          <div>
            <span>{{ $t("programList.academicYear") }} : </span>
            <select class="subfilter" v-model="acYear" @change="changeAcYear">
              <option v-for="year in programAcYears" :key="year">{{ year }}</option>
            </select>
            <div style="float:right" v-if="userIsCourseFinalEditor && userCanEditProgram && isEditing && isExchange">
              <span style="font-size: 14px"> {{ $t("general.action") }}: </span>
              <button @click="openModal()" class="button is-small" style="float: right; margin-left: 5px;">{{ $t("general.copyMinor") }}</button>
            </div>
            <div v-if="userCanEditProgram && isEditing">
              <span> Weergave: </span>
              <select class="subfilter" v-model="isNewStyle" @change="toggleStyle($event)">
                <option :key="true" :value="true">Nieuwe Stijl</option>
                <option :key="false" :value="false">Traditioneel</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal v-if="content.CopyMinor" v-show="showConfirmationModal" @close="closeModal" @ok="triggerAction('copy_minor')" :text="content.CopyMinor.details.Text" :header="$t('general.copyMinor')"> </ConfirmationModal>
      <div class="row">
        <div class="col-md-3">
          <side-menu class="vue-menu" :userCanEditProgram="userCanEditProgram && isEditing" />
        </div>
        <div class="col col-md-9 overview-content program-content" id="contents">
          <div class="redit newStyleMargin">
            <h2>{{ $t("menuLabels.Education") }}</h2>
          </div>
          <div v-if="!loading">
            <div v-for="(item, index) in items" :key="index">
              <template v-if="isEditing || ((item.type == 'readonlyBlock' || (item.value !== undefined && item.value.length > 0)) && !item.header.includes('programLinks'))">
                <text-item
                  v-if="item.type == 'text'"
                  v-bind="item"
                  set
                  :edit="isEditing && originalEditorialState !== 'Gereed'"
                  :isNewStyle="isNewStyle"
                  :index="index"
                  :showActionButtons="userIsCourseFinalEditor && userCanEditProgram && isEditing && isExchange"
                  isProgramOverview
                  @change="updateSave"
                  @blur="onBlur"
                  @dirty="onDirty"
                  @requiredFieldNotFilled="requiredFieldNotFilled"
                  @requiredFieldFilled="requiredFieldFilled"
                  @imgChange="updateImage"
                  @imgUpload="addCropper"
                  @triggerAction="triggerAction"
                />
                <banner-text-item v-if="item.type == 'banner-text'" v-bind="item" set :edit="isEditing && originalEditorialState !== 'Gereed'" isProgramOverview @blur="onBlur" @dirty="onDirty" @change="updateSave" :date="programDetails.CoursesReleaseDate" />
                <local-text-item v-if="item.type == 'local-text'" v-bind="item" set :edit="isEditing && originalEditorialState !== 'Gereed'" isProgramOverview @blur="onBlur" @dirty="onDirty" :date="programDetails.CoursesReleaseDate" />
                <link-item v-if="item.type == 'link'" v-bind="item" set :edit="isEditing && originalEditorialState !== 'Gereed'" isProgramOverview @change="updateSave" @blur="onBlur" @dirty="onDirty" />
                <readonly-block v-if="item.type == 'readonlyBlock'" v-bind="item" set :edit="isEditing" />
                <readonly v-if="item.type == 'readonly'" v-bind="item" set :edit="isEditing" />
              </template>
            </div>
          </div>
          <div v-if="loading">
            <div class="argument-error-container" v-if="InvalidParameters == true">
              <p class="argument-error">{{ $t("general.warningArgumentExeption") }}</p>
            </div>
            <spinner v-else />
          </div>
          <div>
            <router-link v-if="!loading" target="_blank" :to="{ name: 'print-program', params: { programSlug: this.$route.params.programSlug, acYear: acYear, isExchange: this.isExchange } }">
              <div class="pdfDownload download-link">{{ $t("general.downloaden2") }}</div>
              <font-awesome-icon icon="file-pdf" class="fontAwesome" />
              <font-awesome-icon icon="print" class="fontAwesome" />
            </router-link>
          </div>
          <div style="clear: both;">
            <router-link v-if="!loading && coursesPublished" target="_blank" :to="{ name: 'print-program-courses', params: { programSlug: this.$route.params.programSlug, acYear: acYear, isExchange: this.isExchange } }">
              <div class="pdfDownload download-link">{{ $t("general.downloaden3") }}</div>
              <font-awesome-icon icon="file-pdf" class="fontAwesome" />
              <font-awesome-icon icon="print" class="fontAwesome" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import Overview from "../mixins/Overview.js";
import LinkItem from "../components/EditPages/LinkItem.vue";
import LocalTextItem from "../components/EditPages/LocalTextItem.vue";
import BannerTextItem from "../components/EditPages/BannerTextItem.vue";
import ReadonlyBlock from "../components/EditPages/ReadonlyBlock.vue";
import { mapState } from "vuex";
import { put, post } from "common/store/storeFunctions.js";
import ConfirmationModal from "../components/ConfirmationModal.vue";

export default {
  components: {
    LinkItem,
    LocalTextItem,
    BannerTextItem,
    ReadonlyBlock,
    ConfirmationModal,
  },
  mixins: [Overview],
  data: function() {
    return {
      activeCroppers: [],
      isNewStyle: false,
      showConfirmationModal: false,
    };
  },
  computed: {
    coursesPublished() {
      return this.programDetails.CoursesPublished;
    },
    programDetails() {
      if (this.acYear) {
        var params = this.$route.params;
        var key = this.acYear + params.programSlug;
        return this.program[key] ? this.program[key].details : null;
      } else {
        return null;
      }
    },
    programAcYears() {
      var slug = this.$route.params.programSlug;
      return this.program[slug] ? this.program[slug].acYears.sort().reverse() : null;
    },
    InvalidParameters() {
      var params = this.$route.params;
      var key = this.acYear + params.programSlug;
      if (this.programArgumentExeption[key] != null) {
        var error = this.programArgumentExeption[key].details[0];
        if (error.message.includes("(errorcode: 2)")) {
          return true;
        }
      }
    },
    acYear() {
      var acYear = this.$route.params.acYear;
      if (acYear == null && this.acYears) {
        acYear = this.acYears.currentAcademicYear;
      }
      return acYear;
    },
    loading() {
      return this.programDetails == null;
    },
    userCanEditProgram() {
      if (this.programDetails) {
        var facultyId = this.programDetails.FacultyId;
        var facultyProgramEditor = false;
        if (this.isExchange && ((this.roles.ExchangeProgramProgramEditor && this.roles.ExchangeProgramProgramEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramProgramFinalEditor && this.roles.ExchangeProgramProgramFinalEditor.indexOf(facultyId) != -1))) {
          facultyProgramEditor = true;
        }
        if (!this.isExchange && ((this.roles.CourseCatalogProgramEditor && this.roles.CourseCatalogProgramEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogProgramFinalEditor && this.roles.CourseCatalogProgramFinalEditor.indexOf(facultyId) != -1))) {
          facultyProgramEditor = true;
        }
        return facultyProgramEditor && this.programDetails.Editable && (this.userIsProgramEditor || this.userIsProgramFinalEditor);
      } else {
        return false;
      }
    },
    ...mapState(["content", "program", "programArgumentExeption"]),
  },
  watch: {
    programDetails: function() {
      this.populateData();
    },
  },
  async created() {
    //get calls are called from sidemenu
    var model = this;
    this.$store.dispatch("getContent", "BannerText");
    this.$store.dispatch("getContent", "CourseText");
    this.$store.dispatch("getContent", "CopyMinor");
    this.populateData();
  },
  methods: {
    onBlur() {
      var model = this;
      if (model.isDirty) {
        model.autoSave();
      }
    },
    updateSave(item, value) {
      this.items[item].set(value);
      var model = this;
      if (model.autoSaveTimerId == null) {
        model.autoSaveTimerId = setInterval(model.autoSave, 300000);
      }
    },
    autoSave() {
      var model = this;
      put(`/api/program/savecopy`, model.programDetails);
    },
    async triggerAction(action) {
      this.showConfirmationModal = false;
      if (action && action != 0) {
        this.saveMessage.type = "actionLoading";
        var triggerActionFlowBody = {
          Id: this.programDetails.Info.Id,
          Action: action,
        };
        try {
          await post(`/api/program/triggerActionFlow`, triggerActionFlowBody);
          this.saveMessage.type = "actionSuccess";
        } catch {
          this.saveMessage.type = "actionError";
        }
        var params = this.$route.params;
        var key = this.acYear + params.programSlug;
        var updateKey = `program.${key}.details`;
        this.$store.commit("resetCacheForKey", updateKey);
        this.items = null;
        await this.$store.dispatch("getProgram", params);
        this.populateData();
        window.onbeforeunload = null;
      }
    },
    toggleStyle(event) {
      var model = this;
      var pd = this.programDetails;
      pd.Info.ProgramDetailsImageUrl = pd.Info.ProgramStructureImageUrl = pd.Info.AdmissionRequirementsImageUrl = null;
      if (event.target.selectedIndex == 1) this.populateData();
      model.isDirty = true;
    },
    populateData() {
      if (this.programDetails === undefined || this.programDetails === null) return;
      this.isEditing = this.userCanEditProgram;
      this.originalEditorialState = this.programDetails.Info.EditorialState;
      var model = this;
      var pd = this.programDetails;
      var lang = this.programDetails.ShowInEnglish ? "en_GB" : null;
      this.isNewStyle = pd.Info.ProgramDetailsImageUrl != null || pd.Info.ProgramStructureImageUrl != null || pd.Info.AdmissionRequirementsImageUrl != null;
      var showCourseInformation = this.coursesPublished != null;
      var courseText;
      if(this.isExchange && this.isEditing && this.originalEditorialState !== 'Gereed'){
        courseText = { "programOverview.courseInformation": model.createText(pd, "programOverview.courseInformation", "Info.CourseInformation", false, lang, 1500,"",0,"",null,false,false, showCourseInformation, true),
        "programOverview.courseInformationComingSoon": model.createBannerText(pd, "programOverview.courseInformationComingSoon", "Info.CourseInformationPreview", this.content.BannerText.details.Text, false, showCourseInformation, lang) };
      }else if(this.isExchange && !this.coursesPublished){
        courseText = { "programOverview.courseInformationComingSoon": model.createBannerText(pd, "programOverview.courseInformationComingSoon", "Info.CourseInformationPreview", this.content.BannerText.details.Text, false, showCourseInformation, lang) };
      }else if(this.isExchange && this.coursesPublished){
        courseText = { "programOverview.courseInformation": model.createText(pd, "programOverview.courseInformation", "Info.CourseInformation", false, lang, 1500,"",0,"",null,false,false, showCourseInformation, true) };
      }
      this.items = {
        "programOverview.programmeDetails.studyplanId": model.createReadOnly(pd, "programOverview.programmeDetails.studyplanId", "Info.StudyplanId", false, true),
        "programOverview.programmeDetails.organisation": model.createReadOnly(pd, "programOverview.programmeDetails.organisation", "Info.SisData.Organisation", false, true),
        //...(!this.isExchange && {"programLinks.honours": model.createLink(pd, "programLinks.honours", "", "Info.LinkToHonours", false)}), //momenteel wordt deze links alleen in COSMOS aangepast
        //...(!this.isExchange && {"programLinks.minors": model.createLink(pd, "programLinks.minors", "", "Info.LinkToMinors", false)}),
        "programOverview.programmeDescription": model.createText(pd, "programOverview.programmeDescription", "Info.Description", true, lang, 1500, null, 210, pd.Info.ProgramDetailsImageUrl, "left", false, this.isNewStyle),
        "programOverview.admissionRequirements": model.createText(pd, "programOverview.admissionRequirements", "Info.AdmissionRequirements", true, lang, 1500, null, 0, pd.Info.AdmissionRequirementsImageUrl, "right", true, this.isNewStyle),
        "programOverview.programmeStructure": model.createText(pd, "programOverview.programmeStructure", "Info.ProgramStructure", true, lang, 1500, null, 0, pd.Info.ProgramStructureImageUrl, "left", true, this.isNewStyle),
        "programOverview.image": model.createText(pd, "programOverview.image", "Info.Image", false, lang, 1500, "hide-header-image", 1500),
        ...courseText,
        "programOverview.video": model.createText(pd, "programOverview.video", "Info.Video", false, lang),
        "programOverview.electives": model.createText(pd, "programOverview.electives", "Info.Electives", !this.isExchange, lang, 1500),
        "programOverview.finalQualifications": model.createText(pd, "programOverview.finalQualifications", "Info.FinalQualifications", true, lang, 1500),
        TER: model.createLink(pd, "programOverview.TER", "Title", "Info.TeachingAndExaminationReq", false),
        "programOverview.studentCounselling": model.createText(pd, "programOverview.studentCounselling", "Info.AcademicCounselling", true, lang, 1500),
        "programOverview.internationalisation": model.createText(pd, "programOverview.internationalisation", "Info.Internationalisation", false, lang, 1500),
        ...(this.isExchange && { "programOverview.costs": model.createText(pd, "programOverview.costs", "Info.Costs", false, lang, 1500) }),
        sisData: model.createReadonlyBlock(pd, "sisData"),
        "programOverview.didacticMethod": model.createText(pd, "programOverview.didacticMethod", "Info.EducationalConcept", false, lang, 1500),
        "programOverview.testingAndAssessment": model.createText(pd, "programOverview.testingAndAssessment", "Info.TestingAndAssessments", false, lang, 1500),
        "programOverview.qualityAssurance": model.createText(pd, "programOverview.qualityAssurance", "Info.QualityAssurance", false, lang, 1500),
        "programOverview.practicalInformation": model.createText(pd, "programOverview.practicalInformation", "Info.PracticalInformation", false, lang, 1500),
      };
    },
    async updateImage(payload) {
      var pd = this.programDetails;
      var model = this;

      var imageUrl = "";
      if (payload.image) {
        var data = {
          image: payload.image,
          name: payload.name,
        };
        var blob = await this.$store.dispatch("postImageToBlob", data);
        imageUrl = blob.link;
      }
      if (payload.type == "Info.Description") {
        pd.Info.ProgramDetailsImageUrl = imageUrl;
      } else if (payload.type == "Info.AdmissionRequirements") {
        pd.Info.AdmissionRequirementsImageUrl = imageUrl;
      } else if (payload.type == "Info.ProgramStructure") {
        pd.Info.ProgramStructureImageUrl = imageUrl;
      }
      this.populateData();
      this.activeCroppers = this.activeCroppers.filter((item) => item != payload.type);
      model.isDirty = true;
    },
    addCropper(itemName) {
      this.activeCroppers.push(itemName);
    },
    changeAcYear(e) {
      var slug = this.$route.params.programSlug;
      this.$router.push({ name: "program-overview", params: { programSlug: slug, acYear: e.target.value } });
    },
    createReadonlyBlock(data, header, extraClass = "") {
      return { type: "readonlyBlock", header, value: data, valuePath: "", extraClass };
    },
    async saveProgram() {
      var model = this;
      model.isSaving = true;
      if (model.programDetails.Info.EditorialState === "Aangemaakt" || (model.programDetails.Info.EditorialState === "Invoeren" && model.saveMessage.requiredFieldsNotFilled.length > 0)) {
        var success = await model.updateProgramToCrm();
        if (success) {
          model.saveMessage.type = "warning";
        }
      } else if ((model.programDetails.Info.EditorialState === "Goedkeuren" || model.programDetails.Info.EditorialState === "Gereed") && model.saveMessage.requiredFieldsNotFilled.length > 0) {
        model.saveMessage.type = "error";
      } else {
        var success = await model.updateProgramToCrm();
        if (success) {
          model.saveMessage.type = "";
        }
      }
      model.isSaving = false;
    },
    async updateProgramToCrm() {
      var model = this;
      model.isDirty = false;

      var params = this.$route.params;
      var key = this.acYear + params.programSlug;
      var updateKey = `program.${key}.details`;
      try {
        await put(`/api/program/update/${model.language}`, model.programDetails);
      } catch {
        model.saveMessage.type = "updateError";
        this.$store.commit("resetCacheForKey", updateKey);
        this.$store.dispatch("getProgram", params);
        return false;
      }

      this.originalEditorialState = this.programDetails.Info.EditorialState;
      this.$store.commit("resetCacheForKey", updateKey);
      await this.$store.dispatch("getProgram", params);
      window.onbeforeunload = null;
    },
    openModal() {
      this.showConfirmationModal = true;
    },
    closeModal() {
      this.showConfirmationModal = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    var model = this;
    if (model.isDirty) {
      var userLang = navigator.language || navigator.userLanguage;
      var question = userLang == "nl" ? "Weet u zeker dat U deze pagina wilt verlaten? Er zijn niet opgeslagen wijzigingen." : "Do you want to leave this page? Changes you made may not be saved.";
      const answer = window.confirm(question);
      if (answer) {
        window.onbeforeunload = null;
        model.clearAutoSave(model);
        if (to.name === "autosaves") {
          var autoSavesObject = {};
          autoSavesObject.Item = this.programDetails;
          autoSavesObject.Id = this.programDetails.Info.Id;
          autoSavesObject.Type = "programInfo";
          this.$store.commit("setAutoSaveIdTo", autoSavesObject);
        }
        next();
      } else next(false);
    } else {
      model.clearAutoSave(model);
      if (to.name === "autosaves") {
        var autoSavesObject = {};
        autoSavesObject.Item = this.programDetails;
        autoSavesObject.Id = this.programDetails.Info.Id;
        autoSavesObject.Type = "programInfo";
        this.$store.commit("setAutoSaveIdTo", autoSavesObject);
      }
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      var toParams = to.params;
      if (from.name == "autosaves") {
        var acYear = vm.acYears.currentAcademicYear;
        if (toParams.acYear) {
          acYear = toParams.acYear;
        }
        var key = acYear + toParams.programSlug;
        var updateKey = `program.${key}.details`;
        vm.$store.commit("resetCacheForKey", updateKey);
      }
      vm.$store.dispatch("getProgram", toParams);
    });
  },
};
</script>
<style lang="less">
@import "../assets/overview.less";

a.details.hva-caret-right {
  margin-left: 26px;
  margin-bottom: 15px;
  display: inline-block;
}
#app {
  .argument-error-container {
    margin-top: 25px;
  }
}
.text.hide-header-image:not(.nl):not(.en) h3 {
  display: none;
}
.newStyleMargin {
  margin-bottom: 20px;
}
.bannerText table {
    width: 100% !important;
}
</style>

<style scoped lang="less">
.button {
  background-color: white;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #25167a;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;

  &.is-small {
    border-radius: 3px;
    font-size: 0.75rem;
    border: 1px solid #dbdbdb;
    height: 2.25em;
    display: inline-block;
  }
}
</style>
